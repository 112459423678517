(function ($) {
    // Handler for .ready() called.
    var processing;
    /** Custom Validator**/
    $.validator.addMethod("alphaonly", function (value, element) {
        var regex = new RegExp("^[a-zA-Z ]+$"); //[a-zA-Z0-9]
        var key = value;

        if (!regex.test(key)) {
            return false;
        }
        return true;
    }, "Please use only alphabetic characters or space.");

    //Validation for ABS form
    $.validator.addMethod("alphanum", function (value, element) {
        var regex = new RegExp("^[-a-zA-Z0-9_\\-!&#*'\" ]+$"); //[a-zA-Z0-9]
        var key = value;

        if (!regex.test(key)) {
            return false;
        }
        return true;
    }, "Please use only alphanumeric characters.");

    $.validator.addMethod("notempty", function (value, element) {
        var key = value,
            tempValue = $.trim(value);

        if (tempValue == '') {
            return false;
        }
        return true;
    }, "Please enter a value.");

    $.validator.addMethod("nophp", function (value, element) {
        value = _convertSpecialChars(value);
        if (/<\?[=|php]?[\s\S]*?\?>/i.test(value)) {
            return false;
        }
        return true;
    }, "Only plain text allowed.");

    $.validator.addMethod("nohtml", function (value, element) {
        value = _convertSpecialChars(value);
        if (/<\/?[a-z][\s\S]*>/i.test(value)) {
            return false;
        }
        return true;
    }, "No html allowed.");

    $.validator.addMethod("validzip", function (value, element) {
        return this.optional(element) || /^\d{5}(?:[-,\s]\d{4})?$/.test(value);
    }, "Please enter a valid zipcode.");

    $.validator.addMethod("validPhone", function (value, element) {
        value = value.replace(/[^0-9\-]/g, '');
        this.optional(element) || !value.match(/1-?\d{3}-?\d{3}-?\d{4}$/);
    }, "Please specify a valid phone number.");

    function _convertSpecialChars(html) {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }

    if ($("#fldphone").length > 0) {
        $("#fldphone").mask("1 (999) - 999 - 9999");
    }

    $('#state').on('change', function(){
        if(isEmpty($(this).val())){
            $(this).addClass('off');
        } else {
            $(this).removeClass('off');
        }
    });

    $("#entry-form").validate({
        ignore: ".ignore",
        errorElement: "span",
        errorPlacement: function (error, element) {
            error.appendTo(element.parents('.field-input'));
        },
        highlight: function (element) {
            $(element).parents('.field-input').addClass('has-error');
        },
        unhighlight: function (element) {
            $(element).parents('.field-input').removeClass('has-error');
        },
        rules: {
            'entry[fullname]': { required: true, notempty: true, nohtml: true, nophp: true },
            'entry[address1]': { required: true, notempty: true, nohtml: true, nophp: true },
            'entry[address2]': { nohtml: true, nophp: true },
            'entry[city]': { required: true, notempty: true, nohtml: true, nophp: true },
            'entry[state]': { required: true},
            'entry[zip]': { required: true, validzip: true },
            'entry[country]': { required: true, notempty: true, nohtml: true, nophp: true },
            'entry[email]': { required: true, email: true, notempty: true, nohtml: true, nophp: true },
            'entry[phone]': { required: true },
            'hiddenRecaptcha': {
                required: function () {
                    if (grecaptcha.getResponse() == '') {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
        },
        messages: {
            'entry[fullname]': { required: 'Please enter your name.' },
            'entry[address1]': { required: 'Please enter address 1.' },
            'entry[city]': {required: 'Please enter city.'},
            'entry[state]': { required: 'Please select a state.' },
            'entry[zip]': {required: 'Please enter a zip code.'},
            'entry[country]': {required: 'Please enter country.'},
            'entry[email]': { required: 'Please enter an email address.', email: 'Please enter a valid email address.' },
            'phone': {required: 'Please enter phone number.' },
        },
        invalidHandler: function (event, validator) {
            $('html, body').animate({
                scrollTop: $(validator.errorList[0].element).parents('.field-group').offset().top
            }, 800);
        },
        submitHandler: function (form) {
            //new FormData(this),
            processForm(new FormData(form));
            return false;
        }
    });

    


    //Create expression and trap all external links
    $.expr[':'].external = function (obj) {
        return !obj.href.match(/^mailto\:/)
            && (obj.hostname != location.hostname);
    };

    $('a[href$=".pdf"]').prop('target', '_blank').addClass('pdf');
    $('a:external:not(.sub)').addClass('external-link');
    $('.external-link').attr('aria-describedby', 'off-site');
    $('a[href="#"]').attr('role', 'button');
    var externalLink;

    function processForm(formData) {
        $('#error-bucket, #success-bucket').fadeOut().remove();
        if (processing) {
            alert('Your submission is being processed, please wait.');
            return;
        }

        var button = $('.frm-submit');
        button.addClass('loading');
        processing = true;
        formData.append('op', 'form-submission');

        $.ajax({
            type: "POST",
            dataType: 'json',
            url: 'misc/ajax.php',
            data: formData,
            contentType: false,
            cache: false,
            processData:false,
            beforeSend: function (xhr) {
                $("#err").fadeOut();
            }
        })
            .done(function (resp) {
                if (!isEmpty(resp.success)) {
                    $('#form-section').toggleClass('off on');
                    $("#entry-form")[0].reset();
                } else if (!isEmpty(resp.error)) {
                    if ($('#entry-form').length > 0) {
                        $('#entry-form').prepend('<div id="error-bucket">' + resp.error + '</div>');
                    }
                }
            })
            .fail(function () {
                // Some Error Occured
            })
            .always(function (data) {
                processing = false;
                grecaptcha.reset();
                button.removeClass('loading');
                if ($('#error-bucket').length > 0) {
                    $('html, body').animate({
                        scrollTop: $('#error-bucket').offset().top - 10
                    }, 800);
                }
            });
    }


    function isEmpty(mixed_var) {
        mixed_var = jQuery.trim(mixed_var);
        var key;
        if (mixed_var === '' || mixed_var === 0 || mixed_var === '0' || mixed_var === null || mixed_var === false || typeof mixed_var === 'undefined') {
            return true;
        }

        if (typeof mixed_var === 'object') {
            for (key in mixed_var) {
                return false;
            }
            return true;
        }
        return false;
    }

})(jQuery);